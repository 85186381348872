'use client';
import { Container } from '@chakra-ui/layout';
import React, { useCallback, useState } from 'react';

import { isValidVIN } from '@/constants/regexp/regexp.constants';
import {
  Campaigns,
  createVinCheckLead,
  getCampaigns,
  getVinVehicle,
} from '@/services/vin-check/VinCheckService';

import Styles from './VinSearch.module.scss';
import VinSearchForm from './VinSearchForm';
import VinSearchResult from './VinSearchResult';

const VinSearch = ({
  dealers,
  activeDealership,
}: {
  dealers: {
    name: string;
    id: string;
  }[];
  activeDealership?: string;
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [isError, setIsError] = useState(false);
  const [isValidInput, setValidInput] = useState(false);
  const [searchResult, setSearchResult] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState([]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value = '' } = e.target;

      setSearchInput(value.toUpperCase());
      if (isValidVIN(value.toUpperCase())) {
        // VIN is valid
        setValidInput(true);
      } else {
        // VIN is invalid
        setValidInput(false);
      }
    },
    [],
  );

  const handleSearchSubmit = useCallback(async () => {
    setIsError(false);
    setSearchResult(false);
    if (searchInput === '') {
      setIsError(true);
      setSearchResult(false);
      return;
    }

    const vehicles: { campaign_number: string }[] =
      await getVinVehicle(searchInput);

    if (vehicles.length) {
      const campaignNumbers = vehicles?.map((vl) => vl.campaign_number);

      const unique = campaignNumbers.filter((campaign_number, i) => {
        return i <= campaignNumbers.indexOf(campaign_number);
      });

      const campaigns = await getCampaigns(unique);

      if (!campaigns.length) {
        setIsError(true);
        setSearchResult(false);
      } else {
        setSearchResult(true);
        setCampaigns(campaigns);
      }
    } else {
      setIsError(true);
    }
  }, [searchInput]);

  const onSubmitForm = async (values: any) => {
    const selectedCampaign: Campaigns | undefined = campaigns.find(
      (cam: Campaigns) => cam.campaign_number === values.inspection_type,
    );

    if (selectedCampaign) {
      await createVinCheckLead({
        dealership_id: values?.dealership_id,
        email: values?.email,
        firstname: values?.firstname,
        lastname: values?.lastname,
        contact_number: values?.contact_number,
        campaign_number: selectedCampaign?.campaign_number,
        vin: searchInput,
        type: selectedCampaign?.type,
        model: selectedCampaign?.model?.id,
      });
      setSearchResult(false);
      setSearchInput('');
    }
  };

  return (
    <Container maxW={'1280'} width={'100%'} className={Styles.vinSearchWrapper}>
      {searchResult ? (
        <VinSearchResult
          campaigns={campaigns}
          setSearchResult={setSearchResult}
          searchText={searchInput}
          onSubmitForm={onSubmitForm}
          dealers={dealers}
          handleInputChange={handleInputChange}
          activeDealership={activeDealership}
        />
      ) : (
        <VinSearchForm
          searchInput={searchInput}
          handleInputChange={handleInputChange}
          handleSubmit={handleSearchSubmit}
          isError={isError}
          isValidInput={isValidInput}
        />
      )}
    </Container>
  );
};

export default VinSearch;

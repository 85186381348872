export const validMobileNumber =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,9}$/im;
export const confidencePage =
  /\/used-cars\/konfidence|\/used-cars\/used|\/used-cars\/used|\/about-kia-konfidence|\/blog/g;
export const nameValidation = /^[a-z][a-z \-_'‘’]+$/i;

export const VIN_REGEX = /\b[A-HJ-NPR-Z0-9]{17}\b/gm;

export const isValidVIN = (vin: string) => {
  return VIN_REGEX.test(vin);
};
export const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

'use client';
import { Heading, Text } from '@chakra-ui/layout';
import Image from 'next/image';

import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import LinkCustom from '../link/LinkCustom';

import Styles from './DiscoverySection.module.scss';

type Props = {
  footer_call_to_action: CallToAction | null;
  footer_call_to_action_image: string | null;
};

const DiscoverySection = ({
  footer_call_to_action,
  footer_call_to_action_image,
}: Props) => {
  return (
    <>
      <div className={Styles.discoverySectionWrapper}>
        <div className={Styles.leftSection}>
          <div className={Styles.imageWrapper}>
            <Image
              src={
                footer_call_to_action_image ? footer_call_to_action_image : ''
              }
              loader={directusImageLoader}
              alt="card"
              layout="fill"
            />
          </div>
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.headingElement}>
            <Heading
              as="h3"
              className={Styles.titleLg}
              marginBottom={{ base: 4, md: 8 }}
            >
              {footer_call_to_action?.heading}
            </Heading>
            <Text className={Styles.titleText}>
              {footer_call_to_action?.subheading}
            </Text>
            <LinkCustom
              className="btnOutlineWhite"
              href={footer_call_to_action?.url || '#'}
            >
              {footer_call_to_action?.text}
            </LinkCustom>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverySection;

'use client';
import { Box, Container, Heading, Text } from '@chakra-ui/layout';
import React from 'react';

import CustomImage from '@/app/_components/custom-image/CustomImage';
import { ImageTransformationKeys } from '@/types/files/files.types';

import Styles from './Banner.module.scss';

type Props = {
  imageKeys: ImageTransformationKeys;
  bannerImage?: string | undefined;
  heading: string;
  description: string;
};

const BannerSection = ({
  imageKeys,
  bannerImage,
  heading,
  description,
}: Props) => {
  return (
    <Box className={Styles.bannerWrapper}>
      <Box className={Styles.bannerImage}>
        <CustomImage imageSrc={bannerImage} imageKeys={imageKeys} />
      </Box>

      <Container
        maxW={'1280'}
        width={'100%'}
        className={Styles.bannerContentWrapper}
      >
        <Box className={Styles.bannerContent}>
          <Box className={Styles.headingElement}>
            <Heading
              as="h1"
              className={Styles.titleXl}
              marginBottom={{ base: 4, md: 8 }}
            >
              {heading}
            </Heading>
          </Box>
          <Text className={Styles.bannerText}>{description}</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default BannerSection;

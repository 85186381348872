import { directusService, limiter } from '@/services/api.service';

export type Campaigns = {
  campaign_number: string;
  description: string;
  model?: {
    name: string;
    id: number;
  };
  name: string;
  type: string;
};

export const getVinCheckPage = limiter.wrap(async () => {
  const { data }: any = await directusService.get('/items/vin_page', {
    params: {
      fields: [
        'header_title',
        'header_subtitle',
        'header_image',
        'footer_call_to_action.*',
        'footer_call_to_action_image',
      ],
      filter: {
        status: {
          _eq: 'published',
        },
      },
    },
  });

  return data[0];
});

export const getVinVehicle = limiter.wrap(async (vin: string) => {
  const { data }: any = await directusService.get('/items/recall_vehicle', {
    params: {
      fields: ['vin', 'campaign_number'],
      filter: {
        vin: {
          _contains: vin,
        },
      },
    },
  });

  return data;
});

export const getCampaigns = limiter.wrap(async (campaigns: string[]) => {
  const { data }: any = await directusService.get('/items/recall_campaigns', {
    params: {
      fields: [
        'campaign_number',
        'model.name',
        'model.id',
        'type',
        'name',
        'description',
      ],
      filter: {
        campaign_number: {
          _in: campaigns,
        },
        status: {
          _eq: 'published',
        },
      },
      limit: -1,
    },
  });

  return data;
});

export const createVinCheckLead = async (leadValues: any) => {
  await directusService.post('/items/recall_leads', leadValues);
};
